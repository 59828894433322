@keyframes MuiLinearProgress-keyframes-indeterminate1 {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes MuiLinearProgress-keyframes-indeterminate2 {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.progress-track {
  background-color: rgb(167, 202, 237);
  height: 4px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.progress-indicator {
  animation: MuiLinearProgress-keyframes-indeterminate1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  background-color: #1976d2;
  top: 0;
  left: 0;
  width: auto;
  bottom: 0;
  position: absolute;
  transition: transform 0.2s linear;
  transform-origin: left;
}

.progress-indicator2 {
  width: auto;
  animation: MuiLinearProgress-keyframes-indeterminate2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
  background-color: #1976d2;
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  transition: transform 0.2s linear;
  transform-origin: left;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}
