input[type='radio'],
label {
  cursor: pointer;
}

label {
  margin-left: 10px;
  align-items: center;
}

input[type='radio'] {
  position: relative;
  height:  var(--size);
  width: var(--size);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

input[type='radio']::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--size);
  height:  var(--size);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid var(--borderColor);
}

input[type='radio']:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--sizeChecked);
  height: var(--sizeChecked);
  border-radius: 50%;
  background-color: var(--color);
  transform: translate(-50%, -50%);
  visibility: visible;
}


input[type='radio']:disabled::before,
input[type='radio']:disabled::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--size);
  height:  var(--size);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(225, 223, 223, 0.65);
  border: 2px solid rgba(225, 223, 223, 0.65);
}
