$color: rgb(204, 204, 204);

.color-picker {
  display: flex;
  border: 1px solid $color;
  border-radius: 4px;
  min-height: 38px;

  box-sizing: border-box;
  outline: 0 !important;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.color-picker__disabled {
  background-color: #e9ecef;
}

.color-picker_input {
  background-color: inherit;
  border: none;
  width: 1px;
  padding: 0;

  &:focus {
    border: none;
  }
}

.color-picker_value-container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;

  padding: 2px 8px;
  overflow: hidden;

  align-items: center;

  box-sizing: border-box;
}

.color-picker_delimiter {
  width: 1px;
  background-color: $color;
  align-self: stretch;
  margin: 8px 0;
}

.color-picker_icon-open {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color;
  padding: 8px;
}

.color-picker_options-container {
  box-sizing: border-box;
  border: 1px solid black;
  background-color: white;
  overflow: hidden;
}

.color-picker_options {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: 4px;
  width: 338px;
}

.color-picker_option {
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid lightgray;
  margin: 4px;
  cursor: pointer;
  transition: transform 200ms ease;
}


.color-picker_button {
  display: block;
  width: 100%; 
  padding: 4px 0;
  cursor: pointer;
  border-radius: 0;
  border: none;
  background-color: rgb(239, 239, 239);
  text-align: center;
  user-select: none;
}

.color-picker_button:active {
  background-color: rgb(220, 220, 220);
}

.color-picker_button__top {
  border-bottom: 1px solid black;
}

.color-picker_button__bottom {
  border-top: 1px solid black;
}

.color-picker_value {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  border-radius: 2px;
  user-select: none;
  box-sizing: border-box;
}

.color-picker_placeholder {
  color: $color;
}


.color-creator-enter {
  opacity: 0;
  height: 33px;
}

.color-creator-enter-active {
  opacity: 1;
  height: 98px;
  transition: opacity 300ms, height 300ms;
}

.color-creator-exit {
  opacity: 1;
  height: 98px;
}

.color-creator-exit-active {
  opacity: 0;
  height: 33px;
  transition: opacity 300ms, height 300ms;
}