@import '~bootstrap/scss/bootstrap';
@import '~@t4b/core/src/css/colors';



body > .container-fluid {
  padding: 0 !important;
}



.wordBreak {
  word-break: break-word;
}

html {
  overflow: hidden;

}


.error {
  background-color: #ffb3b0;
  color: black;
}

.App-header {
  height: calc(100vh - 58px);
  display: flex;
  flex-direction: column;
  color: black;
  text-align: start;

  $bordercolor: rgba(183, 210, 229, 0.8);

  .app-accordion {
    border-color: $bordercolor;
  }

  h2 {
    color: #324766;
  }

  .accordion-hidden .accordion-header {
    border-radius: 5px;
  }

  .accordion-header {
    background-color: white;
    border-color: $bordercolor;

    svg {
      margin-top: auto;
      margin-bottom: auto;
    }

    span {
      color: #324766 !important;
      text-transform: none;
    }
  }

  input,
  select {
    border-color: $bordercolor;
  }

  th,
  td {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
}

.bg-primary-light {
  background-color: #62cbc9;
}

.summary-dark {
  background-color: #2c405a !important;
  color: #fff !important;
  border-color: inherit;
}

.os-content-glue {
  width: 100% !important;
  //margin: 0 !important;
}

.dropleft .dropdown-toggle::before {
  display: none;
}

.pagination-pill {
  .btn-primary {
    border-color: rgba(255, 255, 255, 0.1) !important;
    background-color: #2c405a !important;
  }
}

.pagination-input {
  border-color: rgba(61, 59, 59, 0.2);
  padding-left: 7px;
  border-radius: 5px;
}

.pagination-input:focus {
  border-color: #80bdff;
  border-radius: 5px;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  padding-left: 7px;
}

.page-item.active .page-link {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: #2c405a;
}

.svg-inline--fa {
  height: 1rem;
}

.svg-inline--fa.fa-w-20 {
  width: 1.4rem;
}

.app-sidebar svg {
  font-size: 1.4rem;
  height: 1.4rem;
}

.fa-2x {
  height: 2rem;
}

.app-rightbar .accordion {
  border-radius: 0;
}

.react-datepicker-wrapper {
  display: block;
}

.app-settings {
  button {
    max-width: 70px;
  }

  input,
  select {
    width: 200px !important;
    margin-right: 20px;
  }
}

.app-settings-hidden {
  .form-group {
    margin-bottom: 0 !important;
  }
}

$color-dark: #2c405a;

.input-dark input {
  background-color: $color-dark !important;
  color: white !important;
}

.datepicker-dark .react-datepicker-wrapper input {
  background-color: $color-dark !important;
  color: white !important;
}

select.form-control {
  padding-right: 2.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='48 72, 48 71, 88 39, 100 54, 50 100, 0 53, 13 37'/></svg>")
  no-repeat;
  background-size: 12px;
  background-position: calc(100% - 15px) 10px;
  background-color: white;
}

.input-dark select {
  background-color: $color-dark !important;
  color: white !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='48 72, 48 71, 88 39, 100 54, 50 100, 0 53, 13 37'/></svg>")
  no-repeat;
  background-size: 12px;
  background-position: calc(100% - 15px) 10px;
}

.input-dark button {
  background-color: $color-dark !important;

  border-color: $color-dark !important;

  span {
    color: white !important;
  }
}

.input-green select {
  background-color: rgb(64, 196, 104) !important;
  color: white !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='48 72, 48 71, 88 39, 100 54, 50 100, 0 53, 13 37'/></svg>")
  no-repeat;
  background-size: 12px;
  background-position: calc(100% - 15px) 10px;
}

.input-green input {
  background-color: rgb(64, 196, 104) !important;
  color: white !important;
}

.currency-input select {
  background-color: $color-dark !important;
  color: white !important;
}

.app-accordion .accordion-header {
  justify-content: flex-start;

}

.connector-input select {
  background-color: rgb(43, 119, 141) !important;
  color: white !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='48 72, 48 71, 88 39, 100 54, 50 100, 0 53, 13 37'/></svg>")
  no-repeat;
  background-size: 12px;
  background-position: calc(100% - 15px) 10px;
}

.input-blue select {
  background-color: #30527a !important;
  color: white !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='48 72, 48 71, 88 39, 100 54, 50 100, 0 53, 13 37'/></svg>")
  no-repeat;
  background-size: 12px;
  background-position: calc(100% - 15px) 10px;
}

.input-blue input {
  //background-color: #30527a !important;
  color: white !important;
  position: relative;
}

.accordion-header .book-input select {
  background-color: #6fc1bc;
  color: white !important;
}

.search-input input {
  background-color: rgb(111, 193, 188) !important;
  color: white !important;
}

.icon,
.icon-rotate {
  margin-left: 0.25rem;
}

.ml-20 {
  margin-left: 20px !important;
}

.mlm-20 {
  margin-left: -20px !important;
}

.bg-buy {
  background-color: #007289 !important;
  color: white !important;
}

.bg-sell {
  background-color: tomato !important;
  color: white !important;
}

.bg-profit-plus {
  background-color: #b2d6a9 !important;
  color: white !important;
}

.bg-profit-minus {
  background-color: darksalmon !important;
  color: white !important;
}

.bg-price {
  background-color: lightseagreen !important;
  color: white !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.rightbar-autoswitch {
  input,
  select {
    color: black !important;
  }
}

@media screen and (max-width: 1200px) {
  .app-settings-hidden .form-group {
    margin-bottom: 1rem !important;
  }
}

.execution-feeding-width {
  width: 455px;
}

@media screen and (min-width: 2800px) {
  .execution-feeding-width {
    width: 35rem;
  }
}




.breadcrumb .breadcrumb-item::before {
  content: '';
  padding: 0;
  margin: 0;
}

.input-title {
  color: #324766 !important;
  font-weight: 500;
  text-transform: none;
}

.position-item {
  cursor: cell;
}
.position-item:active {
  transform: scale(1.01);
}
.bg-selected-position {
  background-color: lightgreen;
}
.bg-selected-position:hover {
  background-color: lightgreen !important;
}

.dark-link {
  color: #2c405a !important;
}

.gray-link {
  color: #8dabc4 !important;
}

.blue-link {
  color: #ecf5fd !important;
}

.breadcrumb {
  padding: 0 !important;
}

.bg-color-dark {
  background-color: #2c405a !important;
  color: white !important;
}

.bg-color-dark-1 {
  background-color: #30527a !important;
  color: white !important;
}

.bg-color-blue {
  background-color: #ecf5fd !important;
}

.input-execution {
  color: #2c405a !important;
  font-weight: 500;
}

.hr-platform {
  border-top: 2px solid black !important;
}

.app-rightbar {
  .card-header {
    background-color: #ecf5fd !important;
  }
}

@mixin border-left($width, $color) {
  border-left: $width solid $color !important;
}

.bg-color-green {
  background-color: rgb(64, 196, 104) !important;
}

.bg-color-darkgreen {
  background-color: #2b778d !important;
}

.border-left-olive {
  border-left: 5px solid #6fc1bc;
}

.border-left-green {
  border-left: 5px solid #81c926;
}

.border-left-darkgreen {
  border-left: 5px solid #2b778d;
}

.execution-configuration-item {
  color: white !important;
  font-weight: 500;
}

.nav-dark.card-header span {
  color: #324766 !important;
  font-weight: 500;
}

.fix-settings.card-header span {
  color: #324766 !important;
  font-weight: 500;
}

.color-darkblue {
  color: #324766 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.color-dark {
  color: #324766 !important;
}

.border-none {
  border: none !important;
}

.accordion-custom {
  margin-bottom: 1rem !important;
}

.accordion-custom__toggle {
  background-color: rgba(48, 82, 122, 1) !important;
  min-height: 51px;
}

.accordion-custom.disabled .accordion-custom__toggle {
  background-color: rgba(48, 82, 122, 0.65) !important;
}

.accordion-custom__collapse {
  background-color: rgba(236, 245, 253, 1) !important;
  transition: background-color 0.3s ease-in-out;
  height: 100%;
}

.accordion-custom.disabled .accordion-custom__collapse {
  opacity: 0.65;
}

.search-field {
  transition: height 0.3s ease-out;
  height: 0;
  opacity: 0;
}

.search-field.show {
  height: calc(1rem + 38px);
  opacity: 1;
}

.input {
  width: 200px !important;
  margin-right: 20px;
}

.app-navbar .navbar-brand a {
  cursor: auto;
}

.nav-item a {
  margin: 0 auto !important;
  margin-top: 6px !important;
  position: relative !important;
  margin-left: 11px !important;
  text-decoration: none;
}

.dropdown-menu {
  .dropdown-item {
    margin-left: 0 !important;
  }
}

.nav-item .ml-navbar {
  width: 60px;
}

.highlighted {
  .accordion-custom__collapse {
    background-color: #c4e2fc !important;
  }

  .accordion-custom__toggle {
    background-color: #184d90 !important;
  }
}

.highlighted_activeSwitch {
  .accordion-custom__collapse {
    background-color: #f6f88d !important;
  }

  .accordion-custom__toggle {
    background-color: #7e7d48 !important;
  }
}

.hoverable {
  visibility: hidden;
}

.accordion:hover .hoverable {
  visibility: visible;
}

.btn-apply,
.t4b-bg-dark-button {
  @include button-variant(
      $app-color-dark,
      $app-color-dark,
      darken($app-color-dark, 7.5%),
      darken($app-color-dark, 10%),
      darken($app-color-dark, 10%),
      darken($app-color-dark, 12.5%)
  );
  span {
    color: white;
    font-weight: 500;
  }
}

.btn-all {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.btn-lp {
  display: flex;
  align-items: center;
  height: 38px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.btn-add {
  width: 38px;
  height: 38px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.rbar-none {
  height: 0;
}

.btn-dark-all {
  width: 56px;
  height: 56px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

$color-plus: rgb(64, 196, 104);
$color-conf: rgb(111, 193, 188);
$color-clone: #8DABC4;
$color-delete: #8DABC4;
$color-select: #2b778d;
$color-wallet: rgb(44, 64, 90);
$color-dark-plus: rgb(44, 64, 90);
$color-export: rgb(111, 193, 188);

$btn-colors: (
  'plus': $color-plus,
  'conf': $color-conf,
  'clone': $color-clone,
  'delete': $color-delete,
  'select': $color-select,
  'wallet': $color-wallet,
  'dark-plus': $color-dark-plus,
  'export': $color-export
);

@each $name, $color in $btn-colors {
  .btn-#{$name} {
    @include button-variant($color, $color, darken($color, 7.5%), darken($color, 10%), darken($color, 10%), darken($color, 12.5%));
    color: white !important;
  }
}

.btn-top {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding-top: 6px;
  cursor: pointer;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  @include button-variant(
      $color-dark-plus,
      $color-dark-plus,
      darken($color-dark-plus, 7.5%),
      darken($color-dark-plus, 10%),
      darken($color-dark-plus, 10%),
      darken($color-dark-plus, 12.5%)
  );
}

.btn-top__hidden {
  opacity: 0;
  cursor: unset;
}

.settings-block {
  .settings-block__field {
    span {
      color: #324766 !important;
      font-weight: 500;
      text-transform: none;
    }
    margin-right: 20px;
    width: 200px;
    word-wrap: break-word;

  }

  padding: 0.75rem 1.25rem;
}

.p {
  padding: 0.75rem 1.25rem;
}

.form-control.is-invalid {
  background-image: none;
  padding-right: 0 !important;
}

.opacity-65 {
  opacity: 0.65;
}

.nav-user .dropdown-toggle::after {
  color: white;
}

.nav-user .dropdown-menu {
  left: auto;
  right: 10px;
}

table.app-table td {
  white-space: normal;
}

.bg-filter {
  color: black;
  background-color: rgba(255, 230, 182, 1);
  font-weight: 500;
}

tbody tr:hover .bg-filter {
  background-color: rgba(255, 230, 182, 0.075);
}

.font-700 {
  font-weight: 700;
}

.logs-tabs {
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  border-bottom: none;
}

.log-error {
  background-color: #ffb3b0;
}

.log-warn {
  background-color: #fffce1;
}

.table-fixed {
  table-layout: fixed;

  td {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.log-levels-group .active {
  background-color: $app-color-dark !important;
  color: white !important;
  z-index: 0 !important;
}

.min-w-1024 {
  min-width: 1024px;
}

.conf-table {
  margin-right: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.export-execution-feeding {
  background-color: #2c405a;
  color: white;
  align-items: center;
}

.export-execution-feeding:hover {
  background-color: #3b577a;
  color: white;
  align-items: center;
}

.first-hiden:first-child {
  display: none;
}

.btn-refresh {
  height: 28px;
}

.lpBorder {
  border: 1px solid rgb(141, 171, 196);
  border-radius: 5px;
}

.lpPlusHover{
  border: 1px solid rgb(141, 171, 196);
  border-radius: 5px;
  height: 45px;
}

.lpPlusHover:hover{
  height: 45px;
  background-color: rgba(0, 0, 0, 0.075);
}

.closeBtn {
  transform: rotate(45deg);
  position: relative;
  left: 670px;
}

.closeBtnSyntetic {
  transform: rotate(45deg);
  position: relative;
  left: 708px;
}


.btnDelete {
  width: 28px;
  height: 28px;
  background-color: #cca976 !important; ;
  border-color: #cca976 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnDelete:hover {
  background-color: #cca976 !important ;
  border-color: #cca976 !important;
}

.direction-color {
  color: #324766;
  font-weight: 500;
}

.select-feed {
  .form-label {
    margin-right: 10px;
  }
}

.check-feed {
  .form-check {
    .form-check-input{
      width: 16px;
      height: 16px;
    }
  }
}

.input-file {
  cursor: pointer;
}

.react-datepicker__input-time-container  {
  display: none
}

.app-switch__disabled .app-switch_thumb {
  opacity: 0.22;
  border: 1px solid black;
}
.app-switch__disabled .app-switch_track {
  opacity: 0.22;
  border: 1px solid black;
}


.IsInvalidation {
  color: #a1a2a4;
  font-weight: inherit;
}

.lastQuote{
  color: #212529;
  font-weight: inherit;
}

.wrp-pos {
  position: relative;
}

.wrp-pos-btn {
  position: absolute;
  top: 37px;
  left: 290px;
  z-index: 3147483649 !important;
}

.btn-plus-symbols {
  background-color: rgb(104, 126, 145);
}

.border-radius {
  border-radius: 5px;
  padding: 4px;
  align-items: center;
  justify-content: center;
}

.bg-profit-plus-exp {
  background-color: #007289;
  color: white;
}

.bg-profit-minus-exp {
  background-color: tomato;
  color: white;
}

.zindex2 {
  z-index: 2 !important;
}

.arrow-back {
  svg {
    margin-right: 10px;
    margin-top: 4px;
    cursor: pointer;
  }
}


//======

.app-switch_custom {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  margin-top: 31px;
}

.app-switch_track_custom {
  width: 58px;
  box-sizing: border-box;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  transition: background-color 300ms ease, opacity 200ms ease;
}

.app-switch_thumb_custom {
  width: 100px;
  height: 32px;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 5px;
  background-color: #ec0822;
  transition: left 300ms ease, opacity 200ms ease;
}

.app-switch_thumb_custom_s {
  width: 100px;
  height: 32px;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 5px;
  background-color: #2c405a;
  transition: left 300ms ease, opacity 200ms ease;
}

.app-switch_native_custom {
  position: absolute;
  padding: 0;
  opacity: 0;
}

.app-switch__checked_custom .app-switch_thumb_custom {
  left: 97px;
}

.app-switch__disabled_custom .app-switch_thumb_custom {
  opacity: 0.22;
}

.app-switch__checked_custom .app-switch_thumb_custom_s {
  left: 97px;
}

.app-switch__disabled_custom .app-switch_thumb_custom_s {
  opacity: 0.22;
}

.app-switch__checked_custom .app-switch_track_custom {
  background-color: white;
}

.app-switch__disabled_custom .app-switch_track_custom {
  opacity: 0.22;
}

.b {
  color: white;
  margin: 0 auto;
  z-index: 100;
}
.s {
  color: white;
  margin: 0 auto;
  z-index: 100;
}

.b_b {
  color: black;
  margin: 0 auto;
  z-index: 100;
}
.s_s {
  color: black;
  margin: 0 auto;
  z-index: 100;
}

//===================

.wrpModalExec {
  z-index: 100;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
}

.borderModalExec {
  border-radius: 5px;
}

.wrpInfo {
  z-index: 200;
  color: #212529;
  font-size: 13px;
}

.wrpInfoSymbol {
  z-index: 200;
  color: #212529;
  margin-bottom: 18px;
  display: flex
}

.spanInfo {
  margin-bottom: 8.5px;
}

.wrpSpanInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.spanDirectionBuy {
  background-color: #077289;
  color: white;
  height: 25px;
  text-align: center;
  border-radius: 5px;
  width: 40px;
  margin-right: 18px;
}

.spanDirectionSell {
  background-color: #E96C57;
  color: white;
  height: 25px;
  border-radius: 5px;
  text-align: center;
  width: 40px;
  margin-right: 18px;
}

.marginBlok {
  margin-top: 40px;
}

.marginBlokButtom {
  margin-bottom: 10px;
}

.react-datepicker__navigation:focus {
  outline: none;
}

.accordion-header-custom {
  display: flex !important;
  width: 100% !important;
  color: white !important;
  background: #768CA0 !important;
  padding: 20px;
  border-radius: 7px;
}

.accordion-header-custom-notification {
  display: flex !important;
  width: 100% !important;
  color: white !important;
  background: #324766 !important;
  padding: 20px;
  border-radius: 7px;
}

.accordion-collapse {
  display: flex !important;
  width: 100% !important;
  //color: #4C667B !important;
  background: white !important;
  border-left: 1px solid #768CA0 ;
  border-right: 1px solid #768CA0 ;
  border-bottom: 1px solid #768CA0 ;
  padding: 20px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.accordion-collapse-color {
  //color: #4C667B !important;
  background: white !important;
  border-left: 1px solid #768CA0 ;
  border-right: 1px solid #768CA0 ;
  border-bottom: 1px solid #768CA0 ;
  padding: 20px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.color-checked {
  font-weight: 600;
}

.color-checked-hov:hover {
  color: #7487A5 !important;
}

.icon {
  transition: transform 0.3s ease-out;
}

.icon-rotate {
  transform: rotate(90deg);
}

#JSON-add-file,
#JSON-replace-file,
#JSON-diff-file,
#JSON-rewrite-file,
#CSV-file,
#CSV-add-file,
#CSV-diff-file,
#CSV-rewrite-file,
#Exel-file,
#Exel-add-file,
#Exel-diff-file,
#Exel-rewrite-file,
#Exel,
#Exel-add,
{
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}


.btn-field-index{
  border: 2px solid #2C405A;
  height: 40px;
  width: 322px;
  border-radius: 2px;
  color: #2C405A;
  font-weight: bold;
  background-color: white;
}



.btn-field-index:hover,
.btn-field-index:active,
.btn-field-index:focus {
  border: 2px solid #2C405A;
  height: 40px;
  width: 322px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  background-color: #2C405A !important;
}

.btn-field-index:disabled {
  border: 2px solid gray;
  height: 40px;
  width: 322px;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  background-color: #2C405A;
}

.blockQuote {
  height: 40px;
  width: 172px;
  margin: 0 auto;
  border-radius: 5px;
}

.color-formula{
  color: black;
}

.text-quote {
  justify-content: center;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
}

.name-quote {
  position: relative;
  top: -35px;
  left: 40px;
}

.wpr-formula {
  width: 758px;
  min-height: 69px;
  border-radius: 5px;
  margin-top: 10px;
}

.blockFormula {
  width: 758px;
  min-height: 69px;
  padding-left: 10px;
  font-weight: 400;
  line-height: 23px;
}


.colorText{
  color: red;
  min-width: 363px;
  margin-left: 32px;
}

.checkbox-custom {
  display: flex;
  align-items: center;
}


.settings-block {
  .settings-block__field {
    .test {
      span {
        color: #dc3545 !important;
        font-weight: 500;
        text-transform: none;
      }
    }

    margin-right: 20px;
    width: 200px;
  }

  padding: 0.75rem 1.25rem;
}

.position-button {
  position: absolute;
}

.fontError {
  font-size: 1em !important;
  span {
    font-size: 0.8em !important;
    font-weight: 400  !important;
  }
}

.fontErrorFeed {
  font-size: 0.8em !important;
  font-weight: 400  !important;
}

.fontErrorInput {
  font-size: 1em !important;
  span {
    //font-size: 80% !important;
    font-weight: 400  !important;
  }
}

.positionSwitch {
  position: absolute;
  top: -83px;
  left: 500px;
}

.disabledPlatform {
  color: gray;
  .text-white {
    color: gray !important;
  }
}



.borderSummary {
  width: 100%;

  h4 {
    color: #324766
  }
}

.Summary {
  border-top: 2px solid #A3BFD7;
}

.dealsOrder {
  height: 0.1px;
  border-top: 1px solid #A3BFD773;
  width: 541px;
  margin: 0 auto
}

.dealsOrderMin {
  height: 0.1px;
  border-top: 1px solid #A3BFD773;
  width: 320px;
  margin: 0 auto
}

.buyDeals {
  display: inline-block;
  height: 19px;
  padding: 2px 4px;
  background: #007289;
  border-radius: 2px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 400;
}

.sellDeals {
  display: inline-block;
  padding: 2px 4px;
  height: 19px;
  background-color: #FF6347;
  border-radius: 2px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 400;
}

.minusProfitDeals {
  display: inline-block;
  padding: 2px 4px;
  height: 19px;
  background-color: #99423B;
  border-radius: 2px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 400;
}

.brokerProfit {
  display: inline-flex;
  align-items: center;
  height: 21px;
  background: #30527A;
  border-radius: 2px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 4px;

}

.brokerProfitMinus {
  display: inline-flex;
  align-items: center;
  height: 21px;
  background: #99423B;
  border-radius: 2px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 4px;

}

.Profit {
  color: #30527A;
  font-weight: 600;
  font-size: 14px;
}


.SummaryWrapper {
  align-items: center;
  justify-content: center;
  width: 100%;

  .SummaryTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #7094B3;
    margin-right: 10px;
  }

  .SummaryContent {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #0C3C63;
    border-right: 1px solid #A3BFD7;
    padding-bottom: 5px;
    padding-right: 5px;
    margin-right: 5px;


    .aBook {
      width: 6px;
      height: 12px;
      background: #30527A;
      border-radius: 2px;
      margin-right: 5px;
    }

    .bBook {
      width: 6px;
      height: 12px;
      background: #E2CA48;
      border-radius: 2px;
      margin-right: 5px;
    }

    .buy {
      display: inline-block;
      height: 19px;
      padding: 0px 4px;
      background: #007289;
      border-radius: 2px;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 400;
    }

    .sell {
      display: inline-block;
      padding: 0px 4px;
      height: 19px;
      background-color: #FF6347;
      border-radius: 2px;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 400;
    }

    &_last {
      border:none;
      padding-bottom: 3px;
    }
  }
}

.borderDeal {
  border: 2px solid #A3BFD7;
  border-radius: 10px;
  padding: 16px;
  margin: 8px;
}

.borderAdditional {
  border: 2px solid #A3BFD7;
  border-radius: 10px;
  padding: 16px;
  //margin: 8px;
  width: 370px;
}

.borderAdditionalOpen {
  margin-left: 20px
}
.borderAdditionalClose {
  margin-left: 10px
}

.buyTable {
  display: inline-block;
  height: 19px;
  padding: 0px 4px;
  background: #007289;
  border-radius: 2px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 400;
}

.sellTable {
  display: inline-block;
  padding: 0px 4px;
  height: 19px;
  background-color: #FF6347;
  border-radius: 2px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 400;
}

.testTabs {
  color: #495057 !important;
}



@import 'scss/summary';
@import 'scss/positions';
@import 'scss/info-item';
@import 'scss/progress-bar';
@import 'scss/telemetry';
@import 'scss/page-animation';
@import 'scss/quotes';
@import 'scss/z-indexes';
@import 'scss/widths';
@import 'scss/execution-tool';
@import 'scss/loader';
@import "css/switch-custom";
@import "css/checkbox-custom";



.react-select__control,
.react-select__single-value,
.react-select__indicator {
  background-color: #30527a !important;
  color: white !important;
  //padding-left: 3px;
}

.invalid-feedback-select {
  border: red 1px solid !important;
  border-radius: 6px !important;
}

.blue-inputT input{
  background-color: #30527a !important;
  color: white !important;
}

.activeTest {
  background-color: #d2d1d1 !important;
  color: black !important;
}

.blue-inputT input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  margin-left: .4em;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
}


#tooltip-Choose {
  .tooltip-inner {
    max-width: 250px;
    width: 250px;
  }
}

.channelBar {
  width: 400px !important;
}

.react-select-trading {
  width: 90% !important;
  margin: 0 !important;
  margin-left: 15px !important;
}

.filter__menu {
  color: black !important;
  z-index: 90000 !important;
}

.filter__single-value,
.filter__placeholder{
  color: white !important;
}

.filter__control {
  background: #829baf !important;
  //border: none !important;
  border-color: #586b7a !important;
}


.filter__control--is-focused {
  background: #829baf !important;
  border-color: #586b7a !important;
  box-shadow: none !important;
  outline: none !important;
}

.faTrashCanChannel {
  color: gray !important;
  fill: gray !important;
  margin-top: 42px !important;
}

.size {
  width: 200px !important;
}

.styleWidth {
  width: 342px !important;
}

.checkboxSelect {
  width: 23px;
  height: 23px;
}

.p-component {
  margin-right: 5px !important;
}

.p-multiselect-items-wrapper {
  max-height: 400px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #4338CA;
  background: #EEF2FF;
  padding-left: 50px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
  padding: 0.25rem 1.25rem !important;
  padding-left: 50px !important;
  margin-left: 5px !important;
}

.p-multiselect-token {
  background-color: hsl(0,0%,90%) !important;
  color: black !important;
}

.p-multiselect-item-group {
  padding-bottom: 0.25rem !important;
}


.color-checked-receiver {
  font-weight: 600;
  color: #394C67 !important;
}

.color-checked-receiver:hover {
  color: #7487A5 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: rgba(0, 123, 255, 0.1) !important;
  color: #495057 !important;
  margin-left: 5px !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  color: white !important;
  background: #007bff !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color:  hsl(0,0%,90%) !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #007bff !important
}

////////////////////////////
.AppVertTable {
  border-radius: 5px;
  border: 1px solid #c5dbea; /* Параметры рамки */
  width: 760px;
}

.tableTr {
  th {
    width: 385px
  }
}

.tableTd {
  padding: 0.75rem;
  width: 500px;
}

.tableTd,
.tableTh {
  vertical-align: top;
  border-radius: 5px;
  border-bottom: 1px solid #c5dbea; /* Параметры рамки */
  padding: 0.75rem;
}

.AppVertTable:last-child {
  border-bottom: none
}

.tableTh {
  background-color: #ecf5fd !important;
  color: #324766 !important;
  font-weight: 500;

  p {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    margin: 0;
  }
}

*::-webkit-scrollbar {
  width: 5px;
  background-color: #f9f9fd;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: gray;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: black;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
}

.selectDirection {
  text-align: start !important;
}

.modalDetailsMT5 {
  position: absolute;
  z-index: 100;
  left: 155px;
  top: 377px;
  font-size: 13px;
  cursor: pointer;
  color: #324766 !important;
  text-decoration: underline;
}

.modalDetailsMT4 {
  position: absolute;
  z-index: 100;
  left: 150px;
  top: 182px;
  font-size: 13px;
  cursor: pointer;
  color: #324766 !important;
  text-decoration: underline;
}

.modalDetailsFix {
  position: absolute;
  z-index: 100;
  left: 153px;
  top: 21px;
  font-size: 13px;
  cursor: pointer;
  color: #324766 !important;
  text-decoration: underline;
}

.mAuto {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.SaveTable-custom {
  margin: 0;
  padding: 0;
}

.accardionModal {
  border-bottom: none !important;

  .accordion-header {
    background: rgb(48, 82, 122) !important;
    color: white !important;

    span {
      color: white !important;
    }
  }
}

.accardionModalLast {
  .accordion-header {
    background: rgb(48, 82, 122) !important;
    color: white !important;

    span {
      color: white !important;
    }
  }
}

.button-modal {
  background: rgb(48, 82, 122) !important;
  color: white !important;
}


.modal-header-custom {
  padding: 0 !important;
  padding-top: 10px !important;
  display: flex;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.line {
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
}

.line:before,
.line:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #000;
  z-index: -1;
  position: relative;
  vertical-align: middle;
}

.line:before {
  right: 15px;
  margin: 0 0 0 -100%;
}
.line:after {
  left: 15px;
  margin: 0 -100% 0 0;
}

.checkTimeoutLabel {
  margin-left: 10px;
  margin-bottom: 10px;
}

.headerSpred {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .headerTime {
    text-align: end;
    flex-grow: 1;
  }
}

.widthRuleName {
   width: 230px !important;
}
.widthLeverage {
  width: 90px !important;
}

.isDisabled {
  background-color: #e9ecef !important;
}

.reportTitle {
  font-weight: 600;
  font-size: 16px;
  color: #2C405A;
  margin-bottom: 18px
}

.reportNameDownload {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.icon-faFile {
  color: #2C405A4D !important;
}

.icon-faDownload {
  color: #768CA0 !important;
}

.textStart {
  text-align: start !important;
}

.border-left-dark {
  border-left: 5px solid $color-dark;
}

.bg-color-olive {
  background-color: #6fc1bc !important;
}

.bg-color-raw {
  background-color: #5CA6BC !important;
}

.bgQuotes {
  background-color:#F6FBFF !important;
}

.react-draggable.react-draggable-dragged {
  min-width: 324px !important;
  min-height: 453px !important;
}

