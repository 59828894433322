$app-color-dark: #2C405A;
$app-color-light: #30527A;

$app-color-lightgreen: #81c926;
$app-color-t4b-middle: #2b778d;
$app-color-t4b-light: #6fc1bc;

$app-color-text: #324766;
$app-color-text-light: #3f536e;
$app-color-text-gray: #8dabc4;
$app-border-color: #C5DBEA;

