.animated {
  transition: opacity 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms, transform 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;
}

.in-progress {
  transform: translateY(40px);
  opacity: 0;
}

.completed {
  // transform: translateY(0);
  opacity: 1;
}

.loading-appear {
  transform: translateY(40px);
  opacity: 0;
}

.loading-appear-active {
  transition: opacity 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms, transform 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;

  transform: translateY(40px);
  opacity: 0;
}

.loading-enter {
  transform: translateY(0);
  opacity: 1;
}

.loading-enter-active {
  transition: opacity 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms, transform 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;

  transform: translateY(40px);
  opacity: 0;
}

.loading-enter-done {
  transform: translateY(40px);
  opacity: 0;
}

.loading-exit {
  transform: translateY(40px);
  opacity: 0;
}

.loading-exit-active {
  transition: opacity 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms, transform 300ms cubic-bezier(0.8, 0, 0.6, 1) 0ms;

  transform: translateY(0);
  opacity: 1;
}
